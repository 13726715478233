
import { defineComponent, ref, onMounted, watch, computed } from "vue";
import ApiService from "@/core/services/ApiService";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { IVoucher } from "@/core/data/vouchers";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { reinitializeComponents } from "@/core/plugins/keenthemes";
import { useStore } from "vuex";

export default defineComponent({
    name: "vouchers-listing",
    components: {
        Datatable,
    },
    setup() {
        const loading = ref(true);
        const store = useStore();
        const checkedVouchers = ref([]);
        const tableHeader = ref([
            {
                key: "checkbox",
                sortable: false,
            },
            {
                name: "Promocode",
                key: "promocode",
                sortable: true,
            },
            {
                name: "Email for",
                key: "emailsendfor",
                sortable: true,
            },
            {
                name: "Activations limit",
                key: "activationslimit",
                sortable: true,
            },
            {
                name: "Valid From",
                key: "validfrom",
                sortable: true,
            },
            {
                name: "Valid Untill",
                key: "validto",
                sortable: true,
            },
            {
                name: "Voucher type",
                key: "itemtype",
                sortable: true,
            },
            {
                name: "Status",
                key: "status",
            },
            {
                name: "Voucher range",
                key: "voucherRange",
            },
            {
                name: "Actions",
                key: "actions",
            },
        ]);

        const tableData = ref<Array<IVoucher>>([]);
        const initVoucher = ref<Array<IVoucher>>([]);

        const selectedCustomer = computed(() => {
            return store.getters.selectedCustomer;
        });

        const paginationHeader = ref<any>({ TotalCount: 0 });

        const pagingFilteration = ref<any>({
            paging: {
                pageNumber: 1,
                pageSize: 10,
            },
            filter: {
                searchTerm: "",
                type: "",
            },
            orderBy: {
                orderByField: "",
                orderDesc: false,
            },
        });

        const getVouchersList = () => {
            loading.value = true;
            ApiService.post(`Voucher/List`, pagingFilteration.value)
                .then(({ data, headers }) => {
                    paginationHeader.value = JSON.parse(headers["x-pagination"]);
                    tableData.value.splice(0, tableData.value.length, ...data);
                    initVoucher.value.splice(0, tableData.value.length, ...data);
                    loading.value = false;
                    reinitializeComponents();
                })
                .catch(({ response }) => {
                    loading.value = false;
                    tableData.value.splice(0, tableData.value.length);
                    initVoucher.value.splice(0, tableData.value.length);
                    console.log("response", response);
                });
        };

        watch(
            () => pagingFilteration.value.paging.pageSize,
            () => {
                getVouchersList();
            },
            { deep: true }
        );

        watch(
            () => pagingFilteration.value.paging.pageNumber,
            () => {
                getVouchersList();
            },
            { deep: true }
        );

        watch(
            () => pagingFilteration.value.orderBy,
            () => {
                getVouchersList();
            },
            { deep: true }
        );

        const onOrderByChange = (orderQuery) => {
            pagingFilteration.value.orderBy.orderByField = orderQuery.columnName;
            pagingFilteration.value.orderBy.orderDesc = orderQuery.order === "desc";
        };

        const onItemsPerPageChange = (itemsPerPage) => {
            pagingFilteration.value.paging.pageSize = itemsPerPage;
        };

        const onCurrentChange = (currentPage) => {
            pagingFilteration.value.paging.pageNumber = currentPage;
        };

        onMounted(() => {
            setCurrentPageBreadcrumbs("Vouchers", []);
            getVouchersList();
        });

        const deleteFewVouchers = () => {
            checkedVouchers.value.forEach((item) => {
                deleteData(item);
            });
            checkedVouchers.value.length = 0;
        };

        const deleteVoucher = (id) => {
            Swal.fire({
                title: "Are you sure?",
                text: "You won't be able to revert this!",
                icon: "warning",
                buttonsStyling: false,
                showCancelButton: true,
                confirmButtonText: "Yes, delete it!",
                customClass: {
                    confirmButton: "btn btn-danger",
                    cancelButton: "btn btn-secondary",
                },
            }).then((result) => {
                if (result.isConfirmed) {
                    deleteData(id);
                }
            });
        };

        const sendVoucherInvitation = (id, email) => {
            Swal.fire({
                title: "Send invitation email",
                text: "An email will be send to " + email,
                icon: "warning",
                buttonsStyling: false,
                showCancelButton: true,
                confirmButtonText: "send now",
                customClass: {
                    confirmButton: "btn btn-danger",
                    cancelButton: "btn btn-secondary",
                },
            }).then((result) => {
                if (result.isConfirmed) {
                    sendEmail(id, email);
                }
            });
        };

        const setPrintedStatus = (id) => {
            ApiService.post(`Voucher/${id}/Status`, {})
                .then(({ data }) => {
                    console.log("data", data);
                    getVouchersList();
                    Swal.fire({
                        timer: 1500,
                        text: "Voucher status set as Printed",
                        icon: "success",
                        buttonsStyling: false,
                        confirmButtonText: "Ok, got it!",
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                    });
                })
                .catch(({ response }) => {
                    console.log("response", response);
                    Swal.fire({
                        text: "Sorry, looks like there are some errors detected, please try again.",
                        icon: "error",
                        buttonsStyling: false,
                        confirmButtonText: "Ok, got it!",
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                    });
                });
        };

        const deleteData = (id) => {
            ApiService.delete(`Voucher/${id}`)
                .then(({ data }) => {
                    console.log("data", data);
                    getVouchersList();
                    Swal.fire({
                        timer: 1500,
                        text: "Data has been successfully deleted!",
                        icon: "success",
                        buttonsStyling: false,
                        confirmButtonText: "Ok, got it!",
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                    });
                })
                .catch(({ response }) => {
                    console.log("response", response);
                    Swal.fire({
                        text: "Sorry, looks like there are some errors detected, please try again.",
                        icon: "error",
                        buttonsStyling: false,
                        confirmButtonText: "Ok, got it!",
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                    });
                });
        };

        const sendEmail = (id, email) => {
            ApiService.post(`Voucher/${id}/SendPromocode`, {})
                .then(({ data }) => {
                    console.log("data", data);
                    getVouchersList();
                    Swal.fire({
                        timer: 1500,
                        text: "Email has been send to " + email,
                        icon: "success",
                        buttonsStyling: false,
                        confirmButtonText: "Ok, got it!",
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                    });
                })
                .catch(({ response }) => {
                    console.log("response", response);
                    Swal.fire({
                        text: "Sorry, looks like there are some errors detected, please try again.",
                        icon: "error",
                        buttonsStyling: false,
                        confirmButtonText: "Ok, got it!",
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                    });
                });
        };

        const search = ref<string>("");

        const searchItems = () => {
            getVouchersList();
        };

        return {
            loading,
            tableData,
            tableHeader,
            deleteVoucher,
            sendVoucherInvitation,
            getVouchersList,
            search,
            selectedCustomer,
            searchItems,
            checkedVouchers,
            deleteFewVouchers,
            setPrintedStatus,
            onItemsPerPageChange,
            onCurrentChange,
            onOrderByChange,
            pagingFilteration,
            paginationHeader,
        };
    },
});
